import React from "react";

import GoogleApp from "../../../global/images/Google-app.png";
import AppleApp from "../../../global/images/Apple-app.png";

import CommunityImageMd from "../../../global/images/Community-Logo-300x222.png";
import CommunityImageLg from "../../../global/images/Community-Logo.png";

const CommunityWrap = () => {
  return (
    <div className="wps-home-community-wrap">
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-5c7526b elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="5c7526b"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97753ce"
              data-id="97753ce"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-93cf591 elementor-widget elementor-widget-heading"
                    data-id="93cf591"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Download your free Animal Track App
                      </h2>
                    </div>
                  </div>
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-4e7f1f4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="4e7f1f4"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <div className="elementor-row">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f885c5a"
                          data-id="f885c5a"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-c96d5dd elementor-widget elementor-widget-image"
                                data-id="c96d5dd"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-image">
                                    <a
                                      href="https://play.google.com/store/apps/dev?id=7641361162496762237"
                                      target="blank"
                                    >
                                      <img
                                        width={300}
                                        height={97}
                                        src={GoogleApp}
                                        className="attachment-large size-large"
                                        alt=""
                                        loading="lazy"
                                        style={{
                                          width: "100%",
                                          height: "32.33%",
                                          maxWidth: "300px",
                                        }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dde85f6"
                          data-id="dde85f6"
                          data-element_type="column"
                        >
                          <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                              <div
                                className="elementor-element elementor-element-25637da elementor-widget elementor-widget-image"
                                data-id="25637da"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-image">
                                    <a
                                      href="https://apps.apple.com/us/developer/wildlife-protection-services-pty-ltd/id1554632964"
                                      target="blank"
                                    >
                                      <img
                                        width={300}
                                        height={97}
                                        src={AppleApp}
                                        className="attachment-large size-large"
                                        alt=""
                                        loading="lazy"
                                        style={{
                                          width: "100%",
                                          height: "32.33%",
                                          maxWidth: "300px",
                                        }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-c7c66f6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="c7c66f6"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4862d50"
              data-id="4862d50"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-678ffc9 elementor-widget elementor-widget-heading"
                    data-id="678ffc9"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Join the Animal Track Community
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-029bf08 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="029bf08"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a12d1d"
              data-id="5a12d1d"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-1c63033 elementor-widget elementor-widget-heading"
                    data-id="1c63033"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Together we can improve animal welfare forever
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-67f48f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="67f48f7"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-efbd7eb"
              data-id="efbd7eb"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b1d72b8 elementor-widget elementor-widget-image"
                    data-id="b1d72b8"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          srcSet={`${CommunityImageMd} 300w, ${CommunityImageLg} 449w`}
                          sizes="(max-width: 449px) 100vw, 449px"
                          width={449}
                          height={333}
                          src={CommunityImageLg}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "74.16%",
                            maxWidth: "449px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a5ac65c elementor-widget elementor-widget-heading"
                    data-id="a5ac65c"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Become a Citizen Scientist and join a community
                        <br/>
                        who work together to improve Animal Welfare
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommunityWrap;
