import React from "react";

import ImageWhiteSm from "../../../global/images//AT-WHITE-LOGO-300x83.png";
import ImageWhiteMd from "../../../global/images//AT-WHITE-LOGO-768x212.png";
import ImageWhiteLg from "../../../global/images//AT-WHITE-LOGO.png";

import PhoneIncedentMd from "../../../global/images/at-phone-incedent-158x300.png";
import PhoneIncedentLg from "../../../global/images/at-phone-incedent.png";

import RescueAppLg from "../../../global/images/AT-Rescue-APP.png";
import RescueAppMd from "../../../global/images/AT-Rescue-APP-768x768.png";
import RescueAppSm from "../../../global/images/AT-Rescue-APP-300x300.png";

const AnimalWrap = () => {
  return (
    <div className="wps-home-animal-wrap">
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-a125da5 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="a125da5"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9265410"
            data-id={9265410}
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-a1df810 elementor-widget elementor-widget-heading"
                data-id="a1df810"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Animal Track is super easy to use
                  </h2>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-8996722 elementor-widget elementor-widget-heading"
                data-id={8996722}
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default sub-title">
                    To report a sighting or incident simply
                  </h2>{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e344af2"
            data-id="e344af2"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-52c0853 elementor-hidden-phone elementor-widget elementor-widget-image"
                data-id="52c0853"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-image">
                    <img
                      srcSet={`${ImageWhiteSm} 300w, ${ImageWhiteMd} 768w, ${ImageWhiteLg} 806w`}
                      sizes="(max-width: 806px) 100vw, 806px"
                      width={750}
                      height={208}
                      src={ImageWhiteLg}
                      className="attachment-large size-large"
                      alt=""
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "27.67%",
                        maxWidth: "806px",
                      }}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-8291e54 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="8291e54"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7f34cee"
            data-id="7f34cee"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated wps-animal-wrap-widget-listings">
              <div
                className="elementor-element elementor-element-cb51a44 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="cb51a44"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Open your App
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Fill out the relevant report field
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Log your GPS Location, date &amp; time
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Attach an image if you have one
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">Send</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-957e80c"
            data-id="957e80c"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e844545 elementor-widget elementor-widget-image"
                data-id="e844545"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-image">
                    <img
                      srcSet={`${PhoneIncedentMd} 158w, ${PhoneIncedentLg} 518w`}
                      sizes="(max-width: 518px) 100vw, 518px"
                      width={518}
                      height={985}
                      src={ImageWhiteLg}
                      className="attachment-large size-large"
                      alt=""
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "190.15%",
                        maxWidth: "518px",
                      }}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-737f00c elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="737f00c"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ff92006"
            data-id="ff92006"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated wps-animal-wrap-widget-listings">
              <div
                className="elementor-element elementor-element-9a6a959 elementor-widget elementor-widget-heading"
                data-id="9a6a959"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Incident responses
                  </h2>{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2317534"
            data-id={2317534}
            data-element_type="column"
          >
            <div className="elementor-widget-wrap"></div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-75352c4 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="75352c4"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5b950a5"
            data-id="5b950a5"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated wps-animal-wrap-widget-listings">
              <div
                className="elementor-element elementor-element-ba69b1d elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="ba69b1d"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Incidents send an alert to the nearest rescue agency
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Showing the incident GPS Location, date &amp; time
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-right"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Allowing for an efficient response
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a7fc930"
            data-id="a7fc930"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e13a0e5 elementor-widget elementor-widget-image"
                data-id="e13a0e5"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-image">
                    <img
                      srcSet={`${RescueAppSm} 300w, ${RescueAppMd} 768w, ${RescueAppLg} 1005w`}
                      sizes="(max-width: 1005px) 100vw, 1005px"
                      width={750}
                      height={750}
                      src={RescueAppLg}
                      className="attachment-large size-large"
                      alt=""
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "1005px",
                      }}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AnimalWrap;
