import React from "react";
import AppImage from "../../../global/images/AT-APP2-Photo-Image-1024x760.png";


const AboutWrap = () => {
  return (
    <div>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-dde19d3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="dde19d3"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7124bd2"
              data-id="7124bd2"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-7805f8c elementor-widget elementor-widget-text-editor"
                    data-id="7805f8c"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Millions of Animals are dying and becoming endangered
                          every year.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f063be3 elementor-widget elementor-widget-text-editor"
                    data-id="f063be3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Animals are being injured, not being reported and
                          saved.
                          <br/>
                        </p>
                        <p>
                          Vital data of animal sightings is not being recorded
                          so we cannot make accurate measures to improve their
                          welfare
                        </p>
                        <p>
                          <br/>
                        </p>
                        <p/>
                        <p>
                          <strong>Why ?</strong>
                        </p>
                        <p>
                          <strong>
                            <br/>
                          </strong>
                        </p>
                        <p/>
                        <p>Reporting incidents takes too long</p>
                        <p>
                          Rescue agencies are not connected to an efficient
                          reporting system
                        </p>
                        <p>Recording Wildlife sightings is difficult</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5646a1f"
              data-id="5646a1f"
              data-element_type="column"
            >
              <div className="elementor-column-wrap">
                <div className="elementor-widget-wrap"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-94202a5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="94202a5"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b6bf06e"
              data-id="b6bf06e"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-a0b923c elementor-widget elementor-widget-text-editor"
                    data-id="a0b923c"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>We have created Animal Track to change this</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-94b26f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="94b26f8"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7808f74"
              data-id="7808f74"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-60e505d elementor-widget elementor-widget-heading"
                    data-id="60e505d"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Animal Track provides
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a45aef7 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="a45aef7"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Quick and easy animal incident reporting
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Connected to local rescue agencies
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Faster incident response and rescue times
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Rescue and save more animals
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Collect more valuable Animal data
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-chevron-right"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            Improve measures to protect animals
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5d15cf3"
              data-id="5d15cf3"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-46f8be4 elementor-widget elementor-widget-image"
                    data-id="46f8be4"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width={750}
                          height={557}
                          src={AppImage}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "74.21%",
                            maxWidth: "1109px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutWrap;
