import React from "react";
import {NavLink} from "react-router-dom";

import WhiteLogoSm from "../../global/images/WPS-Logo-White-300x121.png";
import WhiteLogoMd from "../../global/images/WPS-Logo-White.png";

const Header = () => {
  return (
    <div
      data-elementor-type="header"
      data-elementor-id={441}
      className="elementor elementor-441 elementor-location-header"
      data-elementor-settings="[]"
    >
      <div className="elementor-section-wrap">
        <header
          className="elementor-section elementor-top-section elementor-element elementor-element-5b2db3fd elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default wps-site-header"
          data-id="5b2db3fd"
          data-element_type="section"
          data-settings='{"background_background":"classic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
        >
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-row">
              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-53fcacf5 wps-header-logo-box"
                data-id="53fcacf5"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-2f3387a elementor-widget elementor-widget-image"
                      data-id="2f3387a"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image wps-header-logo">
                          <img
                            width={352}
                            height={142}
                            src={WhiteLogoMd}
                            className="attachment-large size-large"
                            alt=""
                            loading="lazy"
                            srcSet={`${WhiteLogoSm} 300w, ${WhiteLogoMd} 352w`}
                            sizes="(max-width: 352px) 100vw, 352px"
                            style={{
                              width: "100%",
                              height: "40.34%",
                              maxWidth: "140px",
                              alignContent: "left"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-32766349 menu-wrp"
                data-id={32766349}
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-37ae0742 elementor-nav-menu__align-center elementor-nav-menu--stretch elementor-nav-menu--indicator-chevron elementor-nav-menu__text-align-center elementor-nav-menu--dropdown-tablet elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                      data-id="37ae0742"
                      data-element_type="widget"
                      data-settings='{"full_width":"stretch","layout":"horizontal","toggle":"burger"}'
                      data-widget_type="nav-menu.default"
                    >
                      <div className="elementor-widget-container">
                        <nav
                          role="navigation"
                          className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-slide"
                        >
                          <ul
                            id="menu-1-37ae0742"
                            className="elementor-nav-menu"
                          >
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-453">
                              <NavLink
                                exact={true}
                                to="/"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-452">
                              <NavLink
                                to="/sponsors"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Sponsors
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-451">
                              <NavLink
                                to="/waps"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                About WPS
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
                              <NavLink
                                to="/contact"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Contact
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                        <div
                          className="elementor-menu-toggle"
                          role="button"
                          tabIndex={0}
                          aria-label="Menu Toggle"
                          aria-expanded="false"
                        >
                          <i className="eicon-menu-bar" aria-hidden="true"/>
                          <span className="elementor-screen-only">Menu</span>
                        </div>
                        <nav
                          className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                          role="navigation"
                          aria-hidden="true"
                        >
                          <ul
                            id="menu-2-37ae0742"
                            className="elementor-nav-menu"
                          >
                            <li
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-433 current_page_item menu-item-453">
                              <NavLink
                                exact={true}
                                to="/"
                                aria-current="page"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Animal Track
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-452">
                              <NavLink
                                to="/sponsors"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Sponsors
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-451">
                              <NavLink
                                to="/waps"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                About WPS
                              </NavLink>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
                              <NavLink
                                to="/contact"
                                activeClassName="elementor-item-active"
                                className="elementor-item"
                              >
                                Contact
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-575dcf39"
                data-id="575dcf39"
                data-element_type="column"
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div
                      className="elementor-element elementor-element-3fe2d48e elementor-shape-square e-grid-align-right e-grid-align-right e-grid-align-center elementor-hidden-phone elementor-hidden-tablet elementor-grid-0 elementor-widget elementor-widget-social-icons"
                      data-id="3fe2d48e"
                      data-element_type="widget"
                      data-widget_type="social-icons.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-social-icons-wrapper elementor-grid">
                          <div className="elementor-grid-item">
                            <a
                              href="https://www.facebook.com/WildlifeProtectionServices"
                              className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-pulse-grow elementor-repeater-item-022bbc9"
                            >
                              <span className="elementor-screen-only">
                                Facebook
                              </span>
                              <i className="fab fa-facebook"/>
                            </a>
                          </div>
                          <div className="elementor-grid-item">
                            <a
                              href="https://www.instagram.com/WildlifeProtectionServices/"
                              className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-pulse-grow elementor-repeater-item-c58d333"
                            >
                              <span className="elementor-screen-only">
                                Instagram
                              </span>
                              <i className="fab fa-instagram"/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
