import React from "react";

import AppContentSm from "../../../global/images/AT-Save-Image-300x300.png";
import AppContentMd from "../../../global/images/AT-Save-Image-768x768.png";
import AppContentLg from "../../../global/images/AT-Save-Image.png";

import SightingsSm from "../../../global/images/AT-Sightings-Bird-300x300.png";
import SightingsMd from "../../../global/images/AT-Sightings-Bird-768x768.png";
import SightingsLg from "../../../global/images/AT-Sightings-Bird.png";

import RoadKillSm from "../../../global/images/AT-Roadkill-Image-300x300.png";
import RoadKillMd from "../../../global/images/AT-Roadkill-Image-768x768.png";
import RoadKillLg from "../../../global/images/AT-Roadkill-Image.png";

import ComputerImage from "../../../global/images/Computer-1024x924.png";

const AppContent = () => {
  return (
    <div className="wps-home-app-content-wrap">
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-c2dd600 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="c2dd600"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd0bbc1"
              data-id="bd0bbc1"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-3757a41 elementor-widget elementor-widget-heading"
                    data-id="3757a41"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Animal Track gives you the power to
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-1978c34 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="1978c34"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f4ceb17"
              data-id="f4ceb17"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-070ade9 elementor-widget elementor-widget-heading"
                    data-id="070ade9"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Rescue Animals
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-9db4248 elementor-widget elementor-widget-text-editor"
                    data-id="9db4248"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container rescue-animal">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Incidents send an alert to the nearest rescue agency
                        </p>
                        <p>Sharing the location and incident details</p>
                        <p>Rescue agencies can now respond more efficiently</p>
                        <p>
                          You can also follow the status of their incident from
                          being sent to responded to
                        </p>
                        <p>on their Animal Track app</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-148ca3c"
              data-id="148ca3c"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-6feee6d elementor-widget elementor-widget-image"
                    data-id="6feee6d"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          srcSet={`${AppContentSm} 300w, ${AppContentMd} 768w, ${AppContentLg} 1005w`}
                          sizes="(max-width: 1005px) 100vw, 1005px"
                          width={750}
                          height={750}
                          src={AppContentLg}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "1005px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-0efb0d6 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="0efb0d6"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-84b993b"
              data-id="84b993b"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-458bbef elementor-widget elementor-widget-heading"
                    data-id="458bbef"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Record Wildlife Sightings
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ca875bd elementor-widget elementor-widget-text-editor"
                    data-id="ca875bd"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container rescue-animal">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Data of wildlife populations and locations is vital
                          for us to be able to accurately monitor species and
                          ensure they are not in danger
                        </p>
                        <p>
                          With your Animal Track App you can record any animal
                          sightings and the information is stored into our
                          global datalake allowing authorised animal welfare
                          organisations access to monitor it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c854c42"
              data-id="c854c42"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-f92f3e9 elementor-widget elementor-widget-image"
                    data-id="f92f3e9"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          srcSet={`${SightingsSm} 300w, ${SightingsMd} 768w, ${SightingsLg} 1005w`}
                          sizes="(max-width: 1005px) 100vw, 1005px"
                          width={750}
                          height={750}
                          src={SightingsLg}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "1005px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-4c51a2a elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="4c51a2a"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0c41ab7"
              data-id="0c41ab7"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-2954897 elementor-widget elementor-widget-heading"
                    data-id={2954897}
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Prevent Roadkill
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a728a78 elementor-widget elementor-widget-text-editor"
                    data-id="a728a78"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container rescue-animal">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Roadkill is a devastating problem for wildlife in
                          Australia, accounting for millions of animal deaths
                          each year.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          Animal Track allows you to report any road incidents
                          which enables swift removal by local agencies.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          Animal Track data will help us identify high risk
                          areas, so we can implement new measures to prevent
                          further road incident deaths.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c277561"
              data-id="c277561"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-5153405 elementor-widget elementor-widget-image"
                    data-id={5153405}
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          srcSet={`${RoadKillSm} 300w, ${RoadKillMd} 768w, ${RoadKillLg} 1005w`}
                          sizes="(max-width: 1005px) 100vw, 1005px"
                          width={750}
                          height={750}
                          src={RoadKillLg}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "1005px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-a64f1b8 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="a64f1b8"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-05ad861"
              data-id="05ad861"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-22667a0 elementor-widget elementor-widget-heading"
                    data-id="22667a0"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Collect Vital Data
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-6fc1a7e elementor-widget elementor-widget-text-editor"
                    data-id="6fc1a7e"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container rescue-animal">
                      <div className="elementor-text-editor elementor-clearfix">
                        <p>
                          Accurate data of animal populations, injuries, deaths
                          and threats enables us to make necessary measures to
                          improve their welfare.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          Every report is stored into our global datalake. This
                          accessible by authorised organisations who can use the
                          data to improve their research and work.
                        </p>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-58a64dd"
              data-id="58a64dd"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-e4ec47d elementor-widget elementor-widget-image"
                    data-id="e4ec47d"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img
                          width={750}
                          height={677}
                          src={ComputerImage}
                          className="attachment-large size-large"
                          alt=""
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "90.19%",
                            maxWidth: "1305px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppContent;
