import React, {useEffect} from "react";
import SectionWarp from "./Contents/SectionWarp";
import CommunityWrap from "./Contents/CommunityWrap";
import AboutWrap from "./Contents/AboutWrap";
import AnimalWrap from "./Contents/AnimalWrap";
import AppContent from "./Contents/AppContent";
import DownloadWrap from "./Contents/DownloadWrap";

const HomePage = () => {
  useEffect(() => {
    document.title = "Animal Track";
    window.scrollTo(0, 0);
  });

  return (
    <div
      data-elementor-type="wp-page"
      data-elementor-id={433}
      className="elementor elementor-433"
      data-elementor-settings="[]"
    >
      <div className="elementor-inner">
        <div className="elementor-section-wrap">
          <SectionWarp/>
          <CommunityWrap/>
          <AboutWrap/>
          <AnimalWrap/>
          <AppContent/>
          <DownloadWrap/>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
