import React from "react";

import GoogleApp from "../../../global/images/Google-app.png";
import AppleApp from "../../../global/images/Apple-app.png";

const DownloadWrap = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-b90b2e6 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
      data-id="b90b2e6"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b4bb225"
            data-id="b4bb225"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-7fda654 elementor-widget elementor-widget-heading"
                  data-id="7fda654"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Download your free Animal Track App
                    </h2>
                  </div>
                </div>
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-77032c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="77032c9"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-899cfdc"
                        data-id="899cfdc"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-8a71266 elementor-widget elementor-widget-image"
                              data-id="8a71266"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  <a
                                    href="https://play.google.com/store/apps/dev?id=7641361162496762237"
                                    target="blank"
                                  >
                                    <img
                                      width={300}
                                      height={97}
                                      src={GoogleApp}
                                      className="attachment-large size-large"
                                      alt=""
                                      loading="lazy"
                                      style={{
                                        width: "100%",
                                        height: "32.33%",
                                        maxWidth: "300px",
                                      }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-64ec929"
                        data-id="64ec929"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-e101d35 elementor-widget elementor-widget-image"
                              data-id="e101d35"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-image">
                                  <a
                                    href="https://apps.apple.com/us/developer/wildlife-protection-services-pty-ltd/id1554632964"
                                    target="blank"
                                  >
                                    <img
                                      width={300}
                                      height={97}
                                      src={AppleApp}
                                      className="attachment-large size-large"
                                      alt=""
                                      loading="lazy"
                                      style={{
                                        width: "100%",
                                        height: "32.33%",
                                        maxWidth: "300px",
                                      }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadWrap;
