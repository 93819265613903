import React, {Component} from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {Collapse, List, ListItemText, Typography} from "@material-ui/core";

const myData = [
  {
    id: "1",
    nameHeader: "Your own branded application",
    subMenu: [{id: "1", name: "We will use your branding to create your very own App and reporting console"}]
  },
  {
    id: "2",
    nameHeader: "Your own landing page",
    subMenu: [{id: "1", name: "We will customise your own branded landing page on the Animal Track site"}]
  },
  {
    id: "3",
    nameHeader: "Platform development & support",
    subMenu: [{
      id: "1",
      name: "The Animal Track platform will be continually developed and we will provide support to ensure you are getting the most out of it"
    }]
  },
];

class LeftCol extends Component {
  state = {settings: [{id: "1", open: false}, {id: "2", open: false}, {id: "3", open: false}]};

  handleClick = id => {
    this.setState(state => ({
      ...state,
      settings: state.settings.map(item =>
        item.id === id ? {...item, open: !item.open} : item
      )
    }));
  };

  render() {
    const {settings} = this.state;
    return (
      <div style={{marginRight: "15px", color: "white"}}>
        <List component="nav">
          {myData.map(each => (
            <React.Fragment key={each.id}>

              <p style={{display: 'flex', alignItems: 'flex-start', paddingBottom: "20px"}}
                 onClick={() => this.handleClick(each.id)}>
                {settings.find(item => item.id === each.id).open ? <ArrowDropDownIcon fontSize="large"/> :
                  <ArrowRightIcon fontSize="large"/>}
                <ListItemText style={{paddingLeft: "1px", margin: "0"}} className="accord-title" inset
                              primary={<Typography style={{
                                color: "white",
                                fontSize: "28px",
                                fontWeight: "600",
                                fontFamily: "Montserrat"
                              }}>{each.nameHeader}</Typography>}/>
              </p>
              <Collapse
                in={settings.find(item => item.id === each.id).open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {each.subMenu.map(subData => (
                    <p key={subData.id}>
                      <ListItemText style={{fontSize: "30px", paddingLeft: "0px"}} inset primary={<Typography style={{
                        color: "#28303d",
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        lineHeight: "34px"
                      }}>{subData.name}</Typography>}/>
                    </p>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  }
}

export default LeftCol;
