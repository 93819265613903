import React from 'react'
import LeftCol from "./RightCol";
import RightCol from "./LeftCol";

const Collapse = () => {
  return (
    <div className="wps-sponsors-accordian">
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-ece638a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="ece638a"
        data-element_type="section"
        style={{paddingTop: "30px"}}
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row wps-sponsors-accordian-wrapper">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-60ec9c8 item wps-sponsors-accordian-left"
              data-id="60ec9c8"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-9a62d93 elementor-widget elementor-widget-toggle"
                    data-id="9a62d93"
                    data-element_type="widget"
                    data-widget_type="toggle.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-toggle" role="tablist">
                        <div className="elementor-toggle-item">
                          <div
                            id="elementor-tab-title-1611"
                            className="elementor-tab-title"
                            data-tab={1}
                            role="tab"
                            aria-controls="elementor-tab-content-1611"
                            aria-expanded="false"
                          >
                            <RightCol/>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-df98923 item wps-sponsors-accordian-right"
              data-id="df98923"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated" style={{padding: "0"}}>
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-be03d54 elementor-widget elementor-widget-toggle"
                    data-id="be03d54"
                    data-element_type="widget"
                    data-widget_type="toggle.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-toggle" role="tablist">
                        <div className="elementor-toggle-item">
                          <div
                            id="elementor-tab-title-1991"
                            className="elementor-tab-title"
                            data-tab={1}
                            role="tab"
                            aria-controls="elementor-tab-content-1991"
                            aria-expanded="false"
                          >
                            <LeftCol/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Collapse
