import React, {useEffect} from "react";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact - Animal Track";
    window.scrollTo(0, 0);
  });
  return (
    <div
      data-elementor-type="wp-page"
      data-elementor-id={439}
      className="elementor elementor-439"
      data-elementor-settings="[]"
    >
      <div className="elementor-inner">
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-811e72a elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
            data-id="811e72a"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row contact-space">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0901bb6"
                  data-id="0901bb6"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-06079cc elementor-widget elementor-widget-heading"
                        data-id="06079cc"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Contact us now to join the Animal Track project and
                            to install your platform
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-990a625"
                  data-id="990a625"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap">
                    <div className="elementor-widget-wrap"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-56aa3a4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="56aa3a4"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c73d08b"
                  data-id="c73d08b"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-e179ae8 elementor-widget elementor-widget-text-editor"
                        data-id="e179ae8"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-text-editor elementor-clearfix">
                            <p>
                              We are based in <strong>Byron Bay</strong>,
                              <strong> Sydney</strong> and <strong>Perth</strong>
                            </p>
                            <p>
                              We are happy to travel – or why not have an
                              initial chat over the phone ?
                            </p>
                            <p>
                              Call <strong>Adrian</strong> or <strong>Jason</strong> on{" "}
                              <strong>
                                <a
                                  href="tel:1300 498 869"
                                  style={{color: "white"}}
                                >
                                  <u>1300 498 869</u>
                                </a>
                              </strong>{" "}
                              or email:{" "}
                              <strong>
                                <a className="mail-link"
                                   href="mailto:info@wildlifeprotection.com.au"
                                   style={{color: "white"}}
                                >
                                  <u>info@wildlifeprotection.com.au</u>
                                </a>
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
