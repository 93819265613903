// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import React from "react";
import {HashRouter as Router, Route} from "react-router-dom";

import Header from "./components/Common/Header";
import HomePage from "./components/Homepage/HomePage";
import Sponsor from "./components/Sponsor/Sponsor";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Privacy/Privacy";
import Footer from "./components/Common/Footer";

import "./App.css";

import "./global/css/block-library style.min.css";
import "./global/css/block-library theme.min.css";
import "./global/css/components style.min.css";
import "./global/css/block-editor style.min.css";
import "./global/css/nux style.min.css";
import "./global/css/editor style.min.css"

import "./global/css/mediacloud-mux.blocks.style.css"
import "./global/css/mcloud-elementor.css"
import "./global/css/animations.min.css"
import "./global/css/frontend-legacy.min.css"
import "./global/css/frontend.min.css"
import "./global/css/frontend pro.min.css"
import "./global/css/she-header-style.css"
import "./global/css/style.css"
import "./global/css/print.css"

import "./global/icons/font-awesome/css/brands.min.css"
import "./global/icons/font-awesome/css/fontawesome.min.css"
import "./global/icons/font-awesome/css/solid.min.css"
import "./global/icons/eicons/css/elementor-icons.min.css"
import "./global/icons/fontawesome/css/fontawesome-all.min.css"

import "./global/css/post-12.css"
import "./global/css/post-441.css"
import "./global/css/post-545.css"

import "./global/css/style.min.css"

import "./global/css/Homepage.css";
import "./global/css/Sponsor.css"
import "./global/css/About.css"
import "./global/css/Contact.css";
import "./global/css/Terms.css";
import "./global/css/Privacy.css";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_E0NFZFAvIN0uVGLDHHYKKyETh2wJslg",
  authDomain: "pc-api-7641361162496762237-916.firebaseapp.com",
  projectId: "pc-api-7641361162496762237-916",
  storageBucket: "pc-api-7641361162496762237-916.appspot.com",
  messagingSenderId: "17680432555",
  appId: "1:17680432555:web:3825e26acc6dfd43a3a6ee",
  measurementId: "G-RY7JFX1K5Z"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const App = () => {
  logEvent(analytics, 'Website Loaded');
  return (
    <div>
      <Router>
        <div>
          <Header/>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/sponsors" component={Sponsor}/>
          <Route exact path="/waps" component={About}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/terms" component={Terms}/>
          <Route exact path="/privacy" component={Privacy}/>
          <Footer/>
        </div>
      </Router>
    </div>
  );
};

export default App;
