import React, {Component} from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {Collapse, List, ListItemText, Typography} from "@material-ui/core";

const myData = [
  {
    id: "4",
    nameHeader: "Your own database",
    subMenu: [{id: "1", name: "We will use your branding to create your very own App and reporting console"}]
  },
  {
    id: "5",
    nameHeader: "Access to the Global datalake",
    subMenu: [{id: "1", name: "We will customise your own branded landing page on the Animal Track site"}]
  },
  {
    id: "6",
    nameHeader: "Reporting & monitoring system",
    subMenu: [{id: "1", name: "Built-in reporting and analysis of events"}]
  }
];

class RightCol extends Component {
  state = {settings: [{id: "4", open: false}, {id: "5", open: false}, {id: "6", open: false}]};

  handleClick = id => {
    this.setState(state => ({
      ...state,
      settings: state.settings.map(item =>
        item.id === id ? {...item, open: !item.open} : item
      )
    }));
  };

  render() {
    const {settings} = this.state;
    return (
      <div style={{marginRight: "15px", color: "white"}}>
        <List component="nav">
          {myData.map(each => (
            <React.Fragment key={each.id}>

              <p style={{display: 'flex', alignItems: 'flex-start', paddingBottom: "20px"}}
                 onClick={() => this.handleClick(each.id)}>
                {settings.find(item => item.id === each.id).open ? <ArrowDropDownIcon fontSize="large"/> :
                  <ArrowRightIcon fontSize="large"/>}
                <ListItemText style={{paddingLeft: "1px", margin: "0"}} className="accord-title" inset
                              primary={<Typography style={{
                                color: "white",
                                fontSize: "28px",
                                fontWeight: "600",
                                fontFamily: "Montserrat"
                              }}>{each.nameHeader}</Typography>}/>
              </p>
              <Collapse
                in={settings.find(item => item.id === each.id).open}
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding>
                  {each.subMenu.map(subData => (
                    <span key={subData.id}>
                      <ListItemText style={{fontSize: "30px", paddingLeft: "0px"}} inset primary={<Typography style={{
                        color: "#28303d",
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                        lineHeight: "34px"
                      }}>{subData.name}</Typography>}/>
                    </span>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  }
}

export default RightCol;
