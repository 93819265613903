import React, {useEffect} from "react";

import WhiteLogoSm from "../../global/images/WPS-Logo-White-300x121.png";
import WhiteLogoLg from "../../global/images/WPS-Logo-White.png";

import FounderPic1 from "../../global/images/1516286879469.jpeg";
import FounderPic2 from "../../global/images/1516990626601.jpeg";
import DesignerImage from "../../global/images/1613149671304.jpeg";

const About = () => {
  useEffect(() => {
    document.title = "About WPS - Animal Track";
    window.scrollTo(0, 0);
  });
  return (
    <div className="wps-about">
      <div
        data-elementor-type="wp-page"
        data-elementor-id={437}
        className="elementor elementor-437"
        data-elementor-settings="[]"
      >
        <div className="elementor-inner">
          <div className="elementor-section-wrap">
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-61e643e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="61e643e"
              data-element_type="section"
              style={{backgroundColor: "#1A1F2B"}}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4956eac"
                    data-id="4956eac"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-f3ed2f7 elementor-widget elementor-widget-image"
                          data-id="f3ed2f7"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-image">
                              <img
                                srcSet={`${WhiteLogoSm} 300w, ${WhiteLogoLg} 352w`}
                                sizes="(max-width: 352px) 100vw, 352px"
                                width={352}
                                height={142}
                                src={WhiteLogoLg}
                                className="attachment-large size-large"
                                alt=""
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "40.34%",
                                  maxWidth: "352px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-dcb2a82"
                    data-id="dcb2a82"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap">
                      <div className="elementor-widget-wrap"/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-6e11207 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="6e11207"
              data-element_type="section"
              style={{backgroundColor: "#1A1F2B"}}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0021fd2"
                    data-id="0021fd2"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-11cf3c7 elementor-widget elementor-widget-text-editor"
                          data-id="11cf3c7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <p>
                                Wildlife Protection Services has been formed by
                                animal lovers dedicated to providing
                                revolutionary new services to improve global
                                Animal Welfare.
                              </p>
                              <p/>
                              <p>
                                Animal deaths are increasing at alarming rates
                                leading to many species becoming endangered.
                              </p>
                              <p/>
                              <p>
                                We are harnessing the power of technology to
                                produce new products that humans can use to
                                improve the protection of Animals.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bf835f2"
                    data-id="bf835f2"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap">
                      <div className="elementor-widget-wrap"/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-37f4a2a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="37f4a2a0"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-row">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e0cce6a"
                    data-id="e0cce6a"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-267a6f7 elementor-vertical-align-middle elementor-position-top elementor-widget elementor-widget-image-box"
                          data-id="267a6f7"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <figure className="elementor-image-box-img">
                                <img
                                  width={200}
                                  height={200}
                                  src={FounderPic1}
                                  className="attachment-full size-full"
                                  alt=""
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "200px",
                                  }}
                                />
                              </figure>
                              <div className="elementor-image-box-content">
                                <h3 className="elementor-image-box-title">
                                  Adrian Sewell
                                </h3>
                                <p className="elementor-image-box-description">
                                  Founder
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-307308f3 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="307308f3"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator">
                                {" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="elementor-element elementor-element-68a4f749 elementor-shape-rounded elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="68a4f749"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-social-icons-wrapper elementor-grid">
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-grow elementor-repeater-item-8b93740"
                                  target="_blank"
                                  href="#"
                                >
                                  <span className="elementor-screen-only">
                                    Facebook
                                  </span>
                                  <i className="fa fa-facebook" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-d2b7695"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Twitter
                                  </span>
                                  <i className="fa fa-twitter" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-dribbble elementor-animation-grow elementor-repeater-item-0831677"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Dribbble
                                  </span>
                                  <i className="fa fa-dribbble" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-behance elementor-animation-grow elementor-repeater-item-0ac3790"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Behance
                                  </span>
                                  <i className="fa fa-behance" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7130c58f"
                    data-id="7130c58f"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-6c39202 elementor-vertical-align-middle elementor-position-top elementor-widget elementor-widget-image-box"
                          data-id="6c39202"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <figure className="elementor-image-box-img">
                                <img
                                  width={299}
                                  height={299}
                                  src={FounderPic2}
                                  className="attachment-full size-full"
                                  alt=""
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "299px",
                                  }}
                                />
                              </figure>
                              <div className="elementor-image-box-content">
                                <h3 className="elementor-image-box-title">
                                  Jason Pascoe
                                </h3>
                                <p className="elementor-image-box-description">
                                  FOUNDER
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-184807c3 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="184807c3"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator">
                                {" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="elementor-element elementor-element-5c7f5c5f elementor-shape-rounded elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="5c7f5c5f"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-social-icons-wrapper elementor-grid">
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-grow elementor-repeater-item-8b93740"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Facebook
                                  </span>
                                  <i className="fa fa-facebook" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-d2b7695"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Twitter
                                  </span>
                                  <i className="fa fa-twitter" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-dribbble elementor-animation-grow elementor-repeater-item-0831677"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Dribbble
                                  </span>
                                  <i className="fa fa-dribbble" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-behance elementor-animation-grow elementor-repeater-item-0ac3790"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Behance
                                  </span>
                                  <i className="fa fa-behance" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-411fe03d"
                    data-id="411fe03d"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-307576ec elementor-vertical-align-middle elementor-position-top elementor-widget elementor-widget-image-box"
                          data-id="307576ec"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <figure className="elementor-image-box-img">
                                <img
                                  width={547}
                                  height={547}
                                  src={DesignerImage}
                                  className="attachment-full size-full"
                                  alt=""
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "547px",
                                  }}
                                />
                              </figure>
                              <div className="elementor-image-box-content">
                                <h3 className="elementor-image-box-title">
                                  Dennis Sewell
                                </h3>
                                <p className="elementor-image-box-description">
                                  Designer
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-6032cf9 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="6032cf9"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator">
                                {" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="elementor-element elementor-element-201f8e89 elementor-shape-rounded elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons"
                          data-id="201f8e89"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-social-icons-wrapper elementor-grid">
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-grow elementor-repeater-item-8b93740"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Facebook
                                  </span>
                                  <i className="fa fa-facebook" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-d2b7695"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Twitter
                                  </span>
                                  <i className="fa fa-twitter" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-dribbble elementor-animation-grow elementor-repeater-item-0831677"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Dribbble
                                  </span>
                                  <i className="fa fa-dribbble" />
                                </a>
                              </div>
                              <div className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-behance elementor-animation-grow elementor-repeater-item-0ac3790"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Behance
                                  </span>
                                  <i className="fa fa-behance" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
