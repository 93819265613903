import React, {useEffect} from "react";
const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy - Animal Track";
    window.scrollTo(0, 0);
  });
  return (
    <div
      data-elementor-type="wp-page"
      data-elementor-id={441}
      className="elementor elementor-441"
      data-elementor-settings="[]"
    >
      <div className="elementor-inner">
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-833ca69 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
            data-id="833ca69"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e069275"
                  data-id="e069275"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-b53d404 elementor-widget elementor-widget-heading"
                        data-id="b53d404"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            We respect your privacy
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-6cc61f2 elementor-widget elementor-widget-text-editor"
                        data-id="6cc61f2"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-text-editor elementor-clearfix">
                            <p>
                              Wildlife Protection Services Pty Ltd (<strong>WPS</strong>) operates the website
                              www.wildlifeprotection.com.au and associated smart phone Apps – Wildlife Protection and
                              Animal Incident Reporting. It is WPS policy to respect and protect your privacy regarding
                              any information we may collect while operating our websites.
                            </p>
                            <h3>Website Visitors</h3>
                            <p>
                              Like most website operators, WPS collects non-personally-identifying information of the
                              sort that web browsers and servers typically make available, such as the browser type,
                              language preference, referring site, and the date and time of each visitor request. WPS
                              purpose in collecting non-personally identifying information is to better understand how
                              WPS visitors use its website. From time to time, WPS may release
                              non-personally-identifying information in the aggregate, e.g., by publishing a report on
                              trends in the usage of its website.
                            </p>
                            <p>
                              WPS also collects potentially personally-identifying information like Internet Protocol
                              (IP) addresses for logged in users and for users leaving comments on WPS blogs/sites. WPS
                              only discloses logged in user and commenter IP addresses under the same circumstances that
                              it uses and discloses personally-identifying information as described below, except that
                              commenter IP addresses and email addresses are visible and disclosed to the administrators
                              of the blog/site where the comment was left.
                            </p>
                            <h3>Gathering of Personally-Identifying Information</h3>
                            <p>
                              Certain visitors to Wildlife Protection’s websites choose to interact with in ways that
                              require WPS to gather personally-identifying information. The amount and type of
                              information that WPS gathers depends on the nature of the interaction. For example, we ask
                              visitors who sign up to a WPS website of App to provide a username and email address.
                              Those who engage in transactions with WPS are asked to provide additional information,
                              including as necessary the personal and financial information required to process those
                              transactions. In each case, WPS collects such information only in so far as is necessary
                              or appropriate to fulfil the purpose of the visitor’s interaction with Wildlife
                              Protection. WPS does not disclose personally-identifying information other than as
                              described below. And visitors can always refuse to supply personally-identifying
                              information, with the caveat that it may prevent them from engaging in certain
                              website-related activities.
                            </p>
                            <h3>Aggregated Statistics</h3>
                            <p>
                              WPS may collect statistics about the behaviour of visitors to its websites. WPS may
                              display this information publicly or provide it to others. However, WPS does not disclose
                              personally-identifying information other than as described below.
                            </p>
                            <h3>Protection of Certain Personally-Identifying Information</h3>
                            <p>
                              WPS discloses potentially personally-identifying and personally-identifying information
                              only to those of its employees, contractors and affiliated organizations that (i) need to
                              know that information in order to process it on WPS behalf or to provide services
                              available at WPS websites, and (ii) that have agreed not to disclose it to others. Some of
                              those employees, contractors and affiliated organizations may be located outside of your
                              home country; by using WPS websites, you consent to the transfer of such information to
                              them. WPS will not rent or sell potentially personally-identifying and
                              personally-identifying information to anyone. Other than to its employees, contractors and
                              affiliated organizations, as described above, WPS discloses potentially
                              personally-identifying and personally-identifying information only in response to a
                              subpoena, court order or other governmental request, or when WPS believes in good faith
                              that disclosure is reasonably necessary to protect the property or rights of Wildlife
                              Protection, third parties or the public at large. If you are a registered user of an WPS
                              website and have supplied your email address, WPS may occasionally send you an email to
                              tell you about new features, solicit your feedback, or just keep you up to date with
                              what’s going on with WPS and our products. If you send us a request (for example via email
                              or via one of our feedback mechanisms), we reserve the right to publish it in order to
                              help us clarify or respond to your request or to help us support other users. WPS takes
                              all measures reasonably necessary to protect against the unauthorized access, use,
                              alteration or destruction of potentially personally-identifying and personally-identifying
                              information.
                            </p>
                            <h3>Cookies</h3>
                            <p>
                              A cookie is a string of information that a website stores on a visitor’s computer, and
                              that the visitor’s browser provides to the website each time the visitor returns. WPS uses
                              cookies to help WPS identify and track visitors, their usage of WPS website, and their
                              website access preferences. WPS visitors who do not wish to have cookies placed on their
                              computers should set their browsers to refuse cookies before using WPS websites, with the
                              drawback that certain features of WPS websites may not function properly without the aid
                              of cookies.
                            </p>
                            <h3>Business Transfers</h3>
                            <p>
                              If WPS, or substantially all of its assets, were acquired, or in the unlikely event that
                              WPS goes out of business or enters bankruptcy, user information would be one of the assets
                              that is transferred or acquired by a third party. You acknowledge that such transfers may
                              occur, and that any acquirer of WPS may continue to use your personal information as set
                              forth in this policy. If you do not wish your information to be transferred, you can
                              request for your data to be deleted.
                            </p>
                            <h3>Ads</h3>
                            <p>
                              Ads appearing on any of our websites may be delivered to users by advertising partners,
                              who may set cookies. These cookies allow the ad server to recognize your computer each
                              time they send you an online advertisement to compile information about you or others who
                              use your computer. This information allows ad networks to, among other things, deliver
                              targeted advertisements that they believe will be of most interest to you. This Privacy
                              Policy covers the use of cookies by WPS and does not cover the use of cookies by any
                              advertisers.
                            </p>
                            <h3>Privacy Policy Changes</h3>
                            <p>
                              Although most changes are likely to be minor, WPS may change its Privacy Policy from time
                              to time, and in WPS sole discretion. WPS encourages visitors to frequently check this page
                              for any changes to its Privacy Policy. If you have a Wildlife Protection, Animal Incident
                              or Wildlife Reporter account, you might also receive an alert informing you of these
                              changes. Your continued use of this site after any change in this Privacy Policy will
                              constitute your acceptance of such change.
                            </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
