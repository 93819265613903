import React from "react";

import ImageBlack from "../../../global/images/WPS-LOGO-Black.png";
import ImageBlackSm from "../../../global/images/WPS-LOGO-Black-300x121.png";

import ImageWhiteSm from "../../../global/images/AT-WHITE-LOGO-300x83.png";
import ImageWhiteMd from "../../../global/images/AT-WHITE-LOGO-768x212.png";
import ImageWhiteLg from "../../../global/images/AT-WHITE-LOGO.png";

import appImage from "../../../global/images/AT-APP-Photo-Image-1024x760.png";

const SectionWarp = () => {
  return (
    <div className="wps-home-banner-section">
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-0dc7e98 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="0dc7e98"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-91d0bd6 wps-home-banner-content"
            data-id="91d0bd6"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-dc3bd43 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="dc3bd43"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-76c309b"
                    data-id="76c309b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-46ae692 elementor-widget elementor-widget-image"
                        data-id="46ae692"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-image">
                            <img
                              srcSet={`${ImageBlackSm} 300w, ${ImageBlack} 352w`}
                              sizes="(max-width: 352px) 100vw, 352px"
                              width={352}
                              height={142}
                              src={ImageBlack}
                              className="attachment-large size-large"
                              alt=""
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "40.34%",
                                maxWidth: "352px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7a58288"
                    data-id="7a58288"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap"/>
                  </div>
                </div>
              </section>
              <div
                className="elementor-element elementor-element-337919d elementor-widget elementor-widget-image"
                data-id="337919d"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-image">
                    <img
                      srcSet={`${ImageWhiteSm} 300w, ${ImageWhiteMd} 768w, ${ImageWhiteLg} 806w`}
                      sizes="(max-width: 806px) 100vw, 806px"
                      width={750}
                      height={208}
                      src={ImageWhiteLg}
                      className="attachment-large size-large"
                      alt=""
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "27.67%",
                        maxWidth: "806px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-315e5de elementor-widget elementor-widget-heading"
                data-id="315e5de"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Changing the way we rescue and protect Wildlife
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3f9a73e wps-home-banner-image"
            data-id="3f9a73e"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-70a76c2 elementor-widget elementor-widget-image"
                data-id="70a76c2"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-image">
                    <img
                      wwidth={750}
                      height={557}
                      src={appImage}
                      className="attachment-large size-large"
                      alt=""
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "74.21%",
                        maxWidth: "1109px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-71a3d01 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="71a3d01"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67ec7fd"
              data-id="67ec7fd"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-7beb5e7 elementor-widget elementor-widget-heading"
                    data-id="7beb5e7"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Report Rescue Protect Wildlife
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SectionWarp;
