import React from "react";
import {Link, NavLink} from "react-router-dom";

import WhiteLogoSm from "../../global/images/WPS-Logo-White-300x121.png";
import ATWhiteLogoSm from "../../global/images/WPS-Logo-White-300x121.png";
import WhiteLogoMd from "../../global/images/WPS-Logo-White.png";
import ATWhiteLogoMd from "../../global/images/AT-WHITE-LOGO-768x212.png";
import ATWhiteLogoLg from "../../global/images/AT-WHITE-LOGO.png";

const Footer = () => {
  return (
    <div className="wps-fotter">
      <div
        data-elementor-type="footer"
        data-elementor-id={545}
        className="elementor elementor-545 elementor-location-footer"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-7ba4c88f elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="7ba4c88f"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-27815a52"
                  data-id="27815a52"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-f0dcb30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="f0dcb30"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div className="elementor-row wps-fotter-logos">
                            <div
                              className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8482b88 wps-fotter-logo-item wps-fotter-logo-left"
                              data-id="8482b88"
                              data-element_type="column"
                            >
                              <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                  <div
                                    className="elementor-element elementor-element-094c2a9 elementor-widget elementor-widget-image"
                                    data-id="094c2a9"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-image">
                                        <img
                                          width={352}
                                          height={142}
                                          src={WhiteLogoMd}
                                          className="attachment-large size-large"
                                          alt=""
                                          loading="lazy"
                                          srcSet={`${WhiteLogoSm} 300w, ${WhiteLogoMd} 352w`}
                                          sizes="(max-width: 352px) 100vw, 352px"
                                          style={{
                                            width: "100%",
                                            height: "40.34%",
                                            maxWidth: "352px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ffd45ce wps-fotter-logo-item wps-fotter-logo-right"
                              data-id="ffd45ce"
                              data-element_type="column"
                            >
                              <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                  <div
                                    className="elementor-element elementor-element-41a8357 elementor-widget elementor-widget-image"
                                    data-id="41a8357"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-image">
                                        <img
                                          width={750}
                                          height={208}
                                          src={ATWhiteLogoLg}
                                          className="attachment-large size-large"
                                          alt=""
                                          loading="lazy"
                                          srcSet={`${ATWhiteLogoSm} 300w, ${ATWhiteLogoMd} 768w, ${ATWhiteLogoLg} 806w`}
                                          sizes="(max-width: 750px) 100vw, 750px"
                                          style={{
                                            width: "100%",
                                            height: "27.67%",
                                            maxWidth: "806px",
                                            marginBottom: "10px"
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div
                        className="elementor-element elementor-element-2702fb84 elementor-widget elementor-widget-text-editor"
                        data-id="2702fb84"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container font-14">
                          <div className="elementor-text-editor elementor-clearfix">
                            <p>
                              Improving the way we rescue and protect wildlife
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-adc3ba5 wps-footer-social-icons"
                  data-id="adc3ba5"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-2d5471a3 e-grid-align-left e-grid-align-left elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                        data-id="2d5471a3"
                        data-element_type="widget"
                        data-widget_type="social-icons.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-social-icons-wrapper elementor-grid">
                            <div className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-dc10550"
                                href="https://www.facebook.com/WildlifeProtectionServices"
                              >
                                <span className="elementor-screen-only">
                                  Facebook
                                </span>
                                <i className="fab fa-facebook"/>
                              </a>
                            </div>
                            <div className="elementor-grid-item">
                              <a
                                className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-0267196"
                                href="https://www.instagram.com/WildlifeProtectionServices/"
                              >
                                <span className="elementor-screen-only">
                                  Instagram
                                </span>
                                <i className="fab fa-instagram"/>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-1bf62e8e wps-footer-center-menu-wrp"
                  data-id="1bf62e8e"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-a60300b elementor-nav-menu--indicator-classic elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                        data-id="a60300b"
                        data-element_type="widget"
                        data-settings='{"layout":"vertical","toggle":"burger"}'
                        data-widget_type="nav-menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            role="navigation"
                            className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-underline e--animation-fade wps-footer-center-menu"
                          >
                            <ul
                              id="menu-1-a60300b"
                              className="elementor-nav-menu sm-vertical"
                            >
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-453">
                                <NavLink
                                  exact={true}
                                  to="/"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  Animal Track
                                </NavLink>
                              </li>
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-452">
                                <NavLink
                                  to="/sponsors"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  Sponsors
                                </NavLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-451">
                                <NavLink
                                  to="/waps"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  About WPS
                                </NavLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
                                <NavLink
                                  to="/contact"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  Contact
                                </NavLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-454">
                                <NavLink
                                  to="/terms"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  Terms
                                </NavLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-455">
                                <NavLink
                                  to="/privacy"
                                  activeClassName="elementor-item-active"
                                  className="elementor-item"
                                >
                                  Privacy
                                </NavLink>
                              </li>
                            </ul>
                          </nav>
                          {/* <div
                            className="elementor-menu-toggle"
                            role="button"
                            tabIndex={0}
                            aria-label="Menu Toggle"
                            aria-expanded="false"
                          >
                            <i className="eicon-menu-bar" aria-hidden="true" />
                            <span className="elementor-screen-only">Menu</span>
                          </div>
                            <nav
                              className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                              role="navigation"
                              aria-hidden="true"
                            >
                              <ul
                                id="menu-2-a60300b"
                                className="elementor-nav-menu sm-vertical"
                              >
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-453">
                                  <NavLink
                                    exact={true}
                                    to="/"
                                    activeClassName="elementor-item-active"
                                    className="elementor-item"
                                  >
                                    Animal Track
                                  </NavLink>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-435 current_page_item menu-item-452">
                                  <NavLink
                                    to="/sponsors"
                                    activeClassName="elementor-item-active"
                                    className="elementor-item"
                                  >
                                    Sponsors
                                  </NavLink>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-451">
                                  <NavLink
                                    to="/waps"
                                    activeClassName="elementor-item-active"
                                    className="elementor-item"
                                  >
                                    About WPS
                                  </NavLink>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
                                  <NavLink
                                    to="/contact"
                                    activeClassName="elementor-item-active"
                                    className="elementor-item"
                                  >
                                    Contact
                                  </NavLink>
                                </li>
                              </ul>
                            </nav> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-1b4e1d33 wps-footer-right-menu-wrp"
                  data-id="1b4e1d33"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-129fa9ba elementor-widget elementor-widget-heading"
                        data-id="129fa9ba"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Downloads
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-57cbdfba elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="57cbdfba"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <a
                                href="https://play.google.com/store/apps/dev?id=7641361162496762237"
                                target="blank"
                              >
                                <span className="elementor-icon-list-text">
                                  Google Play
                                </span>
                              </a>
                            </li>
                            <li className="elementor-icon-list-item">
                              <a
                                href="https://apps.apple.com/us/developer/wildlife-protection-services-pty-ltd/id1554632964"
                                target="blank"
                              >
                                <span className="elementor-icon-list-text">
                                  App Store
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer
            className="elementor-section elementor-top-section elementor-element elementor-element-769c9fd7 elementor-section-height-min-height elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-items-middle"
            data-id="769c9fd7"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row wps-copyright-wrap">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-13c5056c item wps-copyright-left"
                  data-id="13c5056c"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-6029ab4b elementor-widget elementor-widget-heading"
                        data-id="6029ab4b"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h3 className="elementor-heading-title elementor-size-default">
                            ©2023 Wildlife Protection Services Pty Ltd
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-43594e8d item wps-copyright-right"
                  data-id="43594e8d"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-38cef1b5 elementor-widget elementor-widget-heading"
                        data-id="38cef1b5"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h3 className="elementor-heading-title elementor-size-default">
                            Created by D Dot Design
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="floating-menu float-menu-1">
        <ul className="fm-bar">
          <li className="fm-item-1-0">
            <a target="_self" href="tel:1300 498 869">
              <div className="fm-icon">
                <i className="fas fa-phone-volume"/>
              </div>
              <div className="fm-label"/>
            </a>
          </li>
          <li className="fm-item-1-1">
            <a target="_self" href="mailto:info@wildlifeprotection.com.au">
              <div className="fm-icon">
                <i className="fas fa-envelope"/>
              </div>
              <div className="fm-label"/>
            </a>
          </li>
          <li className="fm-item-1-2">
            <Link to="/contact">
              {/* replace # with download link */}
              <div className="fm-icon">
                <i className="fas fa-cloud-download-alt"/>
              </div>
              <div className="fm-label"/>
            </Link>
          </li>
        </ul>
      </div>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20113949.js"></script>
    </div>
  );
};

export default Footer;
