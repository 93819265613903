import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Collapse from "./Contents/Collapse";
// import MyClass from "./MyClass";
// import MyClass2 from "./MyClass2";
const Sponsor = () => {
  useEffect(() => {
    document.title = "Sponsor - Animal Track";
    window.scrollTo(0, 0);
  });
  return (
    <div
      data-elementor-type="wp-page"
      data-elementor-id={435}
      className="elementor elementor-435"
      data-elementor-settings="[]"
    >
      <div className="elementor-inner">
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-833ca69 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
            data-id="833ca69"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e069275"
                  data-id="e069275"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-b53d404 elementor-widget elementor-widget-heading"
                        data-id="b53d404"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Become an Animal Track sponsor
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-6cc61f2 elementor-widget elementor-widget-text-editor"
                        data-id="6cc61f2"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-text-editor elementor-clearfix">
                            <p>
                              Give back to your supporters and leave them a
                              lasting legacy
                            </p>
                            <p>
                              Give them the tool to become Citizen Scientists
                            </p>
                            <p>
                              Engage them in your work and involve them in the
                              Animal Track community
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-d14fe28 elementor-widget elementor-widget-heading"
                        data-id="d14fe28"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Give them the power to
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-7e40edd elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="7e40edd"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-chevron-right"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Rescue and save animals
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-chevron-right"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Report wildlife sightings &amp; Incidents
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-chevron-right"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Prevent Roadkill
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-chevron-right"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Improve wildlife monitoring &amp; protection
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-92454d2 elementor-widget elementor-widget-heading"
                        data-id="92454d2"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            What you get
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Collapse/>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-5ebc7c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="5ebc7c6"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b08ab52"
                  data-id="b08ab52"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-3c08a60 elementor-widget elementor-widget-heading"
                        data-id="3c08a60"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container" style={{paddingTop: "80px"}}>
                          <h2 className="elementor-heading-title elementor-size-default">
                            We make it easy for your supporters to be part of
                            the Animal Track community
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-fd51b63 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="fd51b63"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6c7ebbd"
                  data-id="6c7ebbd"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-2a8b473 elementor-widget elementor-widget-heading"
                        data-id="2a8b473"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Free App Download
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-32302cd"
                  data-id="32302cd"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-9ef7b5e elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="9ef7b5e"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-circle"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Supporters can download your own branded
                                Animal Track app for free
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-b66c77f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="b66c77f"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c39ec3a"
                  data-id="c39ec3a"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-52bde2f elementor-widget elementor-widget-heading"
                        data-id="52bde2f"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Easy to use
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-68430c4"
                  data-id="68430c4"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-03a9892 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="03a9892"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-circle"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                To report an incident users simply take a photo,
                                fill out the fields and send
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-b2126ab elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="b2126ab"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c7247ca"
                  data-id="c7247ca"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-c14abd6 elementor-widget elementor-widget-heading"
                        data-id="c14abd6"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Follow up
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4b6fd81"
                  data-id="4b6fd81"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-e7853f0 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="e7853f0"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-circle"
                                />
                              </span>
                              <span className="elementor-icon-list-text">
                                Users get auto report updates and cal also check
                                follow on actions
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-29495ce elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="29495ce"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eb0bf98"
                  data-id="eb0bf98"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-297ac3b elementor-widget elementor-widget-heading"
                        data-id="297ac3b"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Animal Track is super secure
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-97c9dbf elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="97c9dbf"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                Hosted on Amazon Web Services with encrypted
                                data
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                Meets the APRA standards - same level security
                                as financial institutions
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                Neither your, nor your supporters information is
                                ever passed to any unauthorised third party or
                                to the global data lake
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                Only animal incident data is collected for the
                                global datalake
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-c905f04 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="c905f04"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-973a808"
                  data-id="973a808"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-a52f216 elementor-widget elementor-widget-heading"
                        data-id="a52f216"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            You can trust us
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-cc5ac4e elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="cc5ac4e"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                We are trusted by Government, Police, Charities,
                                Universities and Law firms
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                Our super secure online platform was developed
                                over two years by a hugely experienced team of
                                designers and programmers
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-text">
                                We understand that the service must be reliable
                                and ethical
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-60ce361 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="60ce361"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6fd884b"
                  data-id="6fd884b"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-a9b58ee elementor-widget elementor-widget-heading"
                        data-id="a9b58ee"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Become an Animal Track sponsor Now
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-52e772fa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="52e772fa"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-531058d2"
                  data-id="531058d2"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-14eed028 elementor-widget elementor-widget-heading"
                        data-id="14eed028"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Together we can improve Animal Welfare forever
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-33e9484 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="33e9484"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7044706"
                  data-id={7044706}
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-01ecb66 elementor-widget elementor-widget-heading"
                        data-id="01ecb66"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2 className="elementor-heading-title elementor-size-default">
                            Contact us for a No Obligation enquiry
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-699a807 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="699a807"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div className="elementor-row">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5593a8f"
                  data-id="5593a8f"
                  data-element_type="column"
                >
                  <div className="elementor-column-wrap elementor-element-populated">
                    <div className="elementor-widget-wrap">
                      <div
                        className="elementor-element elementor-element-30e325b elementor-button-info elementor-align-center elementor-widget elementor-widget-button"
                        data-id="30e325b"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <Link
                              to="/contact"
                              className="elementor-button-link elementor-button elementor-size-xl"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  Contact
                                </span>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Sponsor;
